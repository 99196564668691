function randomInteger(max) {
    return Math.floor(Math.random() * max);
}

function randomText(length, charset = '123456789abcdefghijkmnopqrstuvwxyzABCDEFGHJKLMNPQRSTUVWXYZ!@#$%^&*(){}') {
    const chars = [];
    let idx;
    for (let i = 0; i < length; i += 1) {
        idx = randomInteger(charset.length);
        chars.push(charset.charAt(idx));
    }
    return chars.join('');
}

module.exports = { randomText };
