<template>
    <v-row no-gutters class="ma-0 pa-0" justify="center" style="height: 100%;">
        <v-col cols="12" class="ma-0 pa-0" style="height: 100%;">
            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">Authentication Preferences</h1>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
        <v-row justify="center" class="py-5 mt-8">
            <v-col cols="12" sm="10" lg="8" md="8" xl="6">
            <v-card elevation="4" class="pa-0 mt-5">
                <v-app-bar :style="cardTitleBarStyle" flat>
                    <v-tooltip bottom>
                        <template #activator="{ on, attrs }">
                            <v-btn icon :color="primaryTextColor" @click="$router.go(-1)" v-bind="attrs" v-on="on">
                                <font-awesome-icon :icon="['fas', 'chevron-left']" style="font-size: 20px;" fixed-width/>
                            </v-btn>
                        </template>
                        Back
                    </v-tooltip>
                    <v-toolbar-title :style="cardTitleBarTextStyle">Authentication Preferences</v-toolbar-title>
                </v-app-bar>
                <v-card-text>
                    <template v-if="authnMechanism">
                        <p class="text-overline mb-0">Login method</p>
                        <p class="mb-0 pb-0">
                            <span>{{ authnMechanismDisplayName[authnMechanism] }}</span>
                        </p>
                        <!-- <v-list class="py-0">
                            <v-list-item-group v-model="authnMechanismSelected">
                                <v-list-item v-for="(item, idx) in authnMechanismChoices" :key="idx">
                                <template #default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"/>
                                    </v-list-item-action>
                                    <v-list-item-content>
                                        <v-list-item-title>
                                            {{ item.text }}
                                            <v-tooltip top v-if="item.tooltip">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on" class="ml-2">
                                                        <font-awesome-icon :icon="['fas', 'question-circle']" class="blue--text"/>
                                                    </span>
                                                </template>
                                                <span>{{ item.tooltip }}</span>
                                            </v-tooltip>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </template>
                                </v-list-item>
                            </v-list-item-group>
                        </v-list> -->
                        <!-- <p class="mb-0 pb-0">
                            <span>{{ authnMechanismDisplayName[authnMechanism] ?? authnMechanism }}</span>
                            <v-btn class="blue--text no-print" @click="dialogChangePassword = true" icon>
                                <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                            </v-btn>
                        </p> -->
                        <!-- password settings -->
                        <template v-if="authnMechanismAvailableList.includes('password')">
                        <p class="text-overline mb-0 mt-10">Password</p>
                        <p class="mb-0 pb-0" v-if="isMechanismReadyPassword">
                            <v-btn outlined color="indigo" @click="dialogChangePassword = true">Change password</v-btn>
                        </p>
                        <!-- <p class="mb-0 pb-0" v-if="!isMechanismReadyPassword"> TODO: write the function that will start the setup interactoin to set a password
                            <v-btn outlined color="indigo" @click="dialogChangePassword = true">Set password</v-btn> (coming soon)
                        </p> -->
                        <DialogChangePassword v-model="dialogChangePassword"/>
                        </template>
                        <!-- loginshield settings -->
                        <template v-if="authnMechanismAvailableList.includes('loginshield')">
                            <p class="text-overline mb-0 mt-10">
                                LoginShield
                                <v-tooltip top>
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on">
                                            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" class="orange--text" v-show="!isMechanismReadyLoginShield"/>
                                        </span>
                                    </template>
                                    <span>Your account is not currently protected by LoginShield</span>
                                </v-tooltip>
                            </p>
                            <template v-if="!loginshield">
                                <p class="mb-0 pb-0">
                                    Service temporarily unavailable.
                                </p>
                            </template>
                            <template v-if="loginshield && !loginshield.registered && !loginshield.confirmed">
                                <p class="mb-0 pb-0">
                                Click the button to setup LoginShield for this account:
                                </p>
                                <v-btn color="indigo white--text" @click="activateLoginShield">
                                    <!-- <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span> -->
                                    Activate LoginShield
                                    <!-- <v-img :src="require('@/assets/logo/loginshield/LoginShield_transparent_48x48.png')" :width="18" :height="18" contain/>
                                    <span class="ml-2">Activate</span> -->
                                </v-btn>
                            </template>
                            <!-- TODO: move the loginshield setup to another view or into a sub-component, like how password set/change is in the DialogChangePassword component -->
                            <template v-if="loginshield && loginshield.registered && !loginshield.confirmed">
                                <p class="mb-0 pb-0">
                                You need to login once with LoginShield before you can use it as your regular login method:
                                </p>
                                <!-- <v-btn tile elevation="4" class="green white--text" @click="activateLoginShield">
                                    <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span>
                                </v-btn> -->
                                <v-btn color="indigo white--text" @click="activateLoginShield">
                                    <!-- <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span> -->
                                    Activate LoginShield
                                    <!-- <v-img :src="require('@/assets/logo/loginshield/LoginShield_transparent_48x48.png')" :width="18" :height="18" contain/>
                                    <span class="ml-2">Activate</span> -->
                                </v-btn>
                            </template>
                            <template v-if="loginshield && loginshield.registered && loginshield.confirmed">
                                <!-- <p class="mb-0 pb-0">
                                Ready to activate LoginShield?
                                </p>
                                <v-btn tile elevation="4" class="green white--text" @click="activateLoginShield">
                                    <font-awesome-icon icon="check" fixed-width/><span class="ml-2">Activate</span>
                                </v-btn> -->
                                <v-switch
                                    :value="loginshield.activated"
                                    @input="toggleLoginShieldActivation($event.target.value)"
                                    color="blue"
                                    hide-details
                                >
                                <template v-slot:label>
                                    <span style="margin-left: 4px">Protect this account with LoginShield</span>
                                </template>
                                </v-switch>
                            </template>
                            <!-- <template v-if="loginshield && loginshield.registered && loginshield.confirmed && loginshield.activated">
                                <p class="mb-0 pb-0">
                                LoginShield is activated and protecting your account.
                                </p>
                            </template> -->
                        </template>
                    </template>
                    <template v-if="!authnMechanism">
                        <!-- <p class="mb-0 pb-0">
                            Your authentication settings are not complete
                        </p> -->
                        <v-alert type="error" color="indigo" outlined class="mt-8">
                            <p class="ma-0 pa-0">Your sign-in method isn't set up yet.</p>
                            <p class="ma-0 pa-0 mt-4">
                                <v-btn color="indigo white--text" @click="startSetupActivity">Fix it</v-btn>
                            </p>
                        </v-alert>

                    </template>
                <!-- </v-col>
            </v-row> -->

            <!-- <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0"> -->
                    <p class="text-overline mb-0 mt-12">Session Timeouts</p>
                    <!-- session unlocked idle and unlocked max timeout -->
                    <p class="mb-0 pb-0" style="line-height: 1em;">
                        Lock session after
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('unlockedIdleTimeoutMillis')" style="cursor: pointer;">{{ unlockedIdleTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('unlockedIdleTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        of inactivity, or
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('unlockedMaxTimeoutMillis')" style="cursor: pointer;">{{ unlockedMaxTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('unlockedMaxTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        since last login,
                        whichever is earlier.
                    </p>
                    <!-- session authenticated idle and max timeout -->
                    <p class="mb-0 pb-0 mt-2" style="line-height: 1em;">
                        Auto-logout after
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('authenticatedIdleTimeoutMillis')" style="cursor: pointer;">{{ authenticatedIdleTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('authenticatedIdleTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        of inactivity, or
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('authenticatedMaxTimeoutMillis')" style="cursor: pointer;">{{ authenticatedMaxTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('authenticatedMaxTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        since last login,
                        whichever is earlier.
                    </p>
                    <!-- session idle and max timeout -->
                    <p class="mb-0 pb-0 mt-2" style="line-height: 1em;">
                        Expire session after
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('sessionIdleTimeoutMillis')" style="cursor: pointer;">{{ sessionIdleTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('sessionIdleTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        of inactivity, or
                        <span class="font-weight-bold text-decoration-underline" @click="showDialogEditTimeout('sessionMaxTimeoutMillis')" style="cursor: pointer;">{{ sessionMaxTimeoutDisplay }}</span>
                        <v-btn class="blue--text no-print" @click="showDialogEditTimeout('sessionMaxTimeoutMillis')" icon small>
                            <font-awesome-icon :icon="['fas', 'pencil-alt']" fixed-width size="1x"/>
                        </v-btn>
                        since last login,
                        whichever is earlier.
                    </p>
                    <!-- dialog to edit a timeout -->
                    <v-dialog v-model="dialogEditTimeoutVisible" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Edit {{ dialogEditTimeoutTitle }}</v-card-text>
                            <template v-if="dialogEditTimeoutKey === 'unlockedIdleTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is locked, you are still "logged in" but re-authentication is required to access security settings
                                    or perform sensitive actions. For increased security, you should set this to a small amount of time.
                                    You may be required to re-authenticate even more frequently in some situations.
                                </p>
                                <!-- <p>
                                    For example, online banking websites typically set this value to 15 minutes.
                                </p> -->
                            </template>
                            <template v-if="dialogEditTimeoutKey === 'unlockedMaxTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is locked, you are still "logged in" but re-authentication is required to access security settings
                                    or perform sensitive actions. For increased security, you should set this to a small amount of time.
                                    You may be required to re-authenticate even more frequently in some situations.
                                </p>
                                <!-- <p>
                                    For example, online banking websites typically set this value to 15 minutes.
                                </p> -->
                            </template>
                            <template v-if="dialogEditTimeoutKey === 'authenticatedIdleTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is "logged out", you have to login to access the account.
                                    For increased security, you should set this to a small amount of time.
                                </p>
                                <p class="grey--text text--darken-2">
                                    However, it may be inconvenient to login frequently. If your operating system
                                    is configured to automatically lock after some time, you could extend
                                    the auto-logout here to a longer period of time.
                                </p>
                            </template>
                            <template v-if="dialogEditTimeoutKey === 'authenticatedMaxTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is "logged out", you have to login to access the account.
                                    For increased security, you should set this to a small amount of time.
                                </p>
                                <p class="grey--text text--darken-2">
                                    However, it may be inconvenient to login frequently. If your operating system
                                    is configured to automatically lock after some time, you could extend
                                    the auto-logout here to a longer period of time.
                                </p>
                            </template>
                            <template v-if="dialogEditTimeoutKey === 'sessionIdleTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is expired, the device is no longer trusted and you will need
                                    to use a second factor the next time you authenticate from that device.
                                    Typically, we send an email with a login link as the second factor.
                                    For increased security, you should set this to a small amount of time.
                                </p>
                                <p class="grey--text text--darken-2">
                                    This setting is equivalent to a "trust this device for X days" setting
                                    that you may see on other websites.
                                </p>
                            </template>
                            <template v-if="dialogEditTimeoutKey === 'sessionMaxTimeoutMillis'">
                                <p class="grey--text text--darken-2">
                                    When the session is expired, the device is no longer trusted and you will need
                                    to use a second factor the next time you authenticate from that device.
                                    Typically, we send an email with a login link as the second factor.
                                    For increased security, you should set this to a small amount of time.
                                </p>
                                <p class="grey--text text--darken-2">
                                    This setting is equivalent to a "trust this device for X days" setting
                                    that you may see on other websites.
                                </p>
                            </template>
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form @submit="dialogEditTimeoutSubmit" onSubmit="return false;" @keyup.enter.native="dialogEditTimeoutSubmit">
                                <v-select :items="dialogEditTimeoutChoices" v-model="dialogEditTimeoutValue" :label="dialogEditTimeoutInputLabel"></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="dialogEditTimeoutSubmit" :disabled="!isDialogEditTimeoutFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditTimeoutCancel">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>

                    <!-- old dialogs to remove -->
                    <v-dialog v-model="dialogEditUnlockedIdleTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change session lock</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is locked, you are still "logged in" but re-authentication is required to access security settings
                                or perform sensitive actions. For increased security, you should set this to a small amount of time.
                                You may be required to re-authenticate even more frequently in some situations.
                            </p>
                            <!-- <p>
                                For example, online banking websites typically set this value to 15 minutes.
                            </p> -->
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editUnlockedIdleTimeoutMillisForm" @submit="editUnlockedIdleTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editUnlockedIdleTimeoutMillis">
                                <v-select :items="filteredUnlockedIdleTimeoutChoices" v-model="unlockedIdleTimeoutMillis" label="Automatically lock session after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editUnlockedIdleTimeoutMillis" :disabled="!isEditUnlockedIdleTimeoutFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditUnlockedIdleTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEditUnlockedMaxTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change session lock</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is locked, you are still "logged in" but re-authentication is required to access security settings
                                or perform sensitive actions. For increased security, you should set this to a small amount of time.
                                You may be required to re-authenticate even more frequently in some situations.
                            </p>
                            <!-- <p>
                                For example, online banking websites typically set this value to 15 minutes.
                            </p> -->
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editUnlockedMaxTimeoutMillisForm" @submit="editUnlockedMaxTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editUnlockedMaxTimeoutMillis">
                                <v-select :items="filteredUnlockedMaxTimeoutChoices" v-model="unlockedMaxTimeoutMillis" label="Automatically lock session after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editUnlockedMaxTimeoutMillis" :disabled="!isEditUnlockedMaxTimeoutFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditUnlockedMaxTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEditAuthenticatedIdleTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change auto-logout</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is "logged out", you have to login to access the account.
                                For increased security, you should set this to a small amount of time.
                            </p>
                            <p class="grey--text text--darken-2">
                                However, it may be inconvenient to login frequently. If your operating system
                                is configured to automatically lock after some time, you could extend
                                the auto-logout here to a longer period of time.
                            </p>
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editAuthenticatedIdleTimeoutMillisForm" @submit="editAuthenticatedIdleTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editAuthenticatedIdleTimeoutMillis">
                                <v-select :items="filteredAuthenticatedIdleTimeoutChoices" v-model="authenticatedIdleTimeoutMillis" label="Automatically logout after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editAuthenticatedIdleTimeoutMillis" :disabled="!isEditAuthenticatedIdleTimeoutFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditAuthenticatedIdleTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEditAuthenticatedMaxTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change auto-logout</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is "logged out", you have to login to access the account.
                                For increased security, you should set this to a small amount of time.
                            </p>
                            <p class="grey--text text--darken-2">
                                However, it may be inconvenient to login frequently. If your operating system
                                is configured to automatically lock after some time, you could extend
                                the auto-logout here to a longer period of time.
                            </p>
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editAuthenticatedMaxTimeoutMillisForm" @submit="editAuthenticatedMaxTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editAuthenticatedMaxTimeoutMillis">
                                <v-select :items="filteredAuthenticatedMaxTimeoutChoices" v-model="authenticatedMaxTimeoutMillis" label="Automatically logout after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editAuthenticatedMaxTimeoutMillis" :disabled="!isEditAuthenticatedMaxTimeoutFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditAuthenticatedMaxTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEditSessionIdleTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change session timeout</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is expired, the device is no longer trusted and you will need
                                to use a second factor the next time you authenticate from that device.
                                Typically, we send an email with a login link as the second factor.
                                For increased security, you should set this to a small amount of time.
                            </p>
                            <p class="grey--text text--darken-2">
                                This setting is equivalent to a "trust this device for X days" setting
                                that you may see on other websites.
                            </p>
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editSessionIdleTimeoutMillisForm" @submit="editSessionIdleTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editSessionIdleTimeoutMillis">
                                <v-select :items="filteredSessionIdleTimeoutChoices" v-model="sessionIdleTimeoutMillis" label="Automatically expire session after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editSessionIdleTimeoutMillis" :disabled="!isEditSessionIdleTimeoutMillisFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSessionIdleTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="dialogEditSessionMaxTimeoutMillis" max-width="600">
                        <v-card elevation="4" class="pa-5">
                            <v-card-text class="text-h6 pa-0 pb-3">Change session timeout</v-card-text>
                            <p class="grey--text text--darken-2">
                                When the session is expired, the device is no longer trusted and you will need
                                to use a second factor the next time you authenticate from that device.
                                Typically, we send an email with a login link as the second factor.
                                For increased security, you should set this to a small amount of time.
                            </p>
                            <p class="grey--text text--darken-2">
                                This setting is equivalent to a "trust this device for X days" setting
                                that you may see on other websites.
                            </p>
                            <p class="text-caption grey--text text--darken-2">
                                * selecting this value will also update one or more other timeouts to match.
                            </p>
                            <v-form v-model="editSessionMaxTimeoutMillisForm" @submit="editSessionMaxTimeoutMillis" onSubmit="return false;" @keyup.enter.native="editSessionMaxTimeoutMillis">
                                <v-select :items="filteredSessionMaxTimeoutChoices" v-model="sessionMaxTimeoutMillis" label="Automatically expire session after..."></v-select>
                            </v-form>
                            <v-row justify="center">
                                <v-btn elevation="4" class="blue white--text" @click="editSessionMaxTimeoutMillis" :disabled="!isEditSessionMaxTimeoutMillisFormComplete">
                                    Save
                                </v-btn>
                                <v-btn text class="grey--text" @click="dialogEditSessionMaxTimeoutMillis = false">Cancel</v-btn>
                            </v-row>
                        </v-card>
                    </v-dialog>
                    <!-- reset to defaults -->
                    <p class="mb-0 pb-0 mt-2" style="line-height: 1em;">
                        <v-btn text x-small @click="restoreDefaultTimeouts" color="indigo" class="px-0">Reset to defaults</v-btn>
                    </p>
                <!-- </v-col>
            </v-row> -->
                </v-card-text>
            </v-card>
            </v-col>
        </v-row>
        </v-col>
    </v-row>
</template>
<script>
import { mapState, mapGetters } from 'vuex';
import { toMillis, toText } from '@/sdk/time';
import { AUTHN_LOGINSHIELD, AUTHN_PASSWORD, INTENT_SETUP, RESPONSE_TYPE_INTENT } from '@/sdk/loginfront/login_api_constants.js'; // '@loginfront/login-api-constants-js';
import DialogChangePassword from '@/components/DialogChangePassword.vue';

export default {
    components: {
        DialogChangePassword,
    },
    data: () => ({
        authnMechanismAvailableList: [],
        authnMechanismChoices: [],
        authnMechanismDisplayName: {
            [AUTHN_PASSWORD]: 'Password',
            [AUTHN_LOGINSHIELD]: 'LoginShield',
        },
        authnMechanismTooltip: {
            [AUTHN_PASSWORD]: 'A typical way to login. Risks include weak passwords, stolen passwords, and phishing attacks.',
            [AUTHN_LOGINSHIELD]: 'Secure and convenient replacement for passwords. Login with just one tap.',
        },
        authnMechanism: null, // AUTHN_LOGINSHIELD or AUTHN_PASSWORD
        authnMechanismSelected: null, // integer, index of selected choice in authnMechanismChoices
        authnMechanismInfo: {}, // mechanism name => { object with details }

        editableEmail: null,

        loginshield: null,

        dialogChangePassword: false,

        // unlocked timeout
        unlockedTimeoutChoices: [
            // { text: 'Default', value: 0 },
            { text: '2 min', value: toMillis({ minutes: 2 }) },
            { text: '5 min', value: toMillis({ minutes: 5 }) },
            { text: '10 min', value: toMillis({ minutes: 10 }) },
            { text: '15 min', value: toMillis({ minutes: 15 }) },
            { text: '30 min', value: toMillis({ minutes: 30 }) },
            { text: '45 min', value: toMillis({ minutes: 45 }) },
            { text: '1 hour', value: toMillis({ hours: 1 }) },
            { text: '2 hours', value: toMillis({ hours: 2 }) },
            { text: '3 hours', value: toMillis({ hours: 3 }) },
            { text: '4 hours', value: toMillis({ hours: 4 }) },
            { text: '6 hours', value: toMillis({ hours: 6 }) },
            { text: '8 hours', value: toMillis({ hours: 8 }) },
            { text: '12 hours', value: toMillis({ hours: 12 }) },
            { text: '24 hours', value: toMillis({ hours: 24 }) },
        ],
        unlockedIdleTimeoutChoices: [],
        unlockedMaxTimeoutChoices: [],

        // unlocked idle timeout
        editUnlockedIdleTimeoutMillisForm: null,
        dialogEditUnlockedIdleTimeoutMillis: false,
        unlockedIdleTimeoutMillis: null,
        defaultUnlockedIdleTimeoutMillis: toMillis({ minutes: 15 }), // TODO: get the default value from response of /context API

        // unlocked max timeout
        editUnlockedMaxTimeoutMillisForm: null,
        dialogEditUnlockedMaxTimeoutMillis: false,
        unlockedMaxTimeoutMillis: null,
        defaultUnlockedMaxTimeoutMillis: toMillis({ minutes: 15 }), // TODO: get the default value from response of /context API

        // authenticated timeout
        authenticatedTimeoutChoices: [
            // { text: 'Default', value: 0 },
            { text: '15 min', value: toMillis({ minutes: 15 }) },
            { text: '30 min', value: toMillis({ minutes: 30 }) },
            { text: '45 min', value: toMillis({ minutes: 45 }) },
            { text: '1 hour', value: toMillis({ hours: 1 }) },
            { text: '4 hours', value: toMillis({ hours: 4 }) },
            { text: '8 hours', value: toMillis({ hours: 8 }) },
            { text: '12 hours', value: toMillis({ hours: 12 }) },
            { text: '24 hours', value: toMillis({ hours: 24 }) },
            { text: '1 day', value: toMillis({ days: 1 }) },
            { text: '7 days', value: toMillis({ days: 7 }) },
            { text: '30 days', value: toMillis({ days: 30 }) },
            { text: '90 days', value: toMillis({ days: 90 }) },
        ],
        authenticatedIdleTimeoutChoices: [],
        authenticatedMaxTimeoutChoices: [],

        // authenticated idle timeout  (must be greater than or equal to unlock timeout)
        editAuthenticatedIdleTimeoutMillisForm: null,
        dialogEditAuthenticatedIdleTimeoutMillis: false,
        authenticatedIdleTimeoutMillis: null,
        defaultAuthenticatedIdleTimeoutMillis: toMillis({ minutes: 15 }), // TODO: get the default value from response of /context API

        // authenticated max timeout  (must be greater than or equal to unlock timeout)
        editAuthenticatedMaxTimeoutMillisForm: null,
        dialogEditAuthenticatedMaxTimeoutMillis: false,
        authenticatedMaxTimeoutMillis: null,
        defaultAuthenticatedMaxTimeoutMillis: toMillis({ minutes: 15 }), // TODO: get the default value from response of /context API

        // session timeout
        sessionTimeoutChoices: [
            // { text: 'Default', value: 0 },
            { text: '1 hour', value: toMillis({ hours: 1 }) },
            { text: '2 hours', value: toMillis({ hours: 2 }) },
            { text: '4 hours', value: toMillis({ hours: 4 }) },
            { text: '8 hours', value: toMillis({ hours: 8 }) },
            { text: '12 hours', value: toMillis({ hours: 12 }) },
            { text: '1 day', value: toMillis({ days: 1 }) },
            { text: '7 days', value: toMillis({ days: 7 }) },
            { text: '30 days', value: toMillis({ days: 30 }) },
            { text: '90 days', value: toMillis({ days: 90 }) },
            { text: '180 days', value: toMillis({ days: 180 }) },
            { text: '1 year', value: toMillis({ years: 1 }) },
        ],
        sessionIdleTimeoutChoices: [],
        sessionMaxTimeoutChoices: [],

        // session idle timeout (must be greater than or equal to session authentication timeout)
        editSessionIdleTimeoutMillisForm: null,
        dialogEditSessionIdleTimeoutMillis: false,
        sessionIdleTimeoutMillis: null,
        defaultSessionIdleTimeoutMillis: toMillis({ days: 90 }), // TODO: get the default value from response of /context API

        // session idle timeout (must be greater than or equal to session authentication timeout)
        editSessionMaxTimeoutMillisForm: null,
        dialogEditSessionMaxTimeoutMillis: false,
        sessionMaxTimeoutMillis: null,
        defaultSessionMaxTimeoutMillis: toMillis({ days: 90 }), // TODO: get the default value from response of /context API

        // edit timeout dialog controls
        dialogEditTimeoutVisible: false,
        dialogEditTimeoutTitle: null,
        dialogEditTimeoutKey: null, // one of 'unlockedIdleTimeoutMillis', 'unlockedMaxTimeoutMillis', 'authenticatedIdleTimeoutMillis', 'authenticatedMaxTimeoutMillis', 'sessionIdleTimeoutMillis', or 'sessionMaxTimeoutMillis'
        dialogEditTimeoutValue: null,
        dialogEditTimeoutChoices: [],
        dialogEditTimeoutInputLabel: null, // Automatically lock session after...
    }),
    computed: {
        ...mapState({
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        ...mapGetters({
            isLoading: 'isLoading',
            brandName: 'brandName',
            primaryColor: 'primaryColor',
            primaryTextColor: 'primaryTextColor',
            accentColor: 'accentColor',
            cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            primaryIconStyle: 'primaryIconStyle',
        }),
        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        sessionIdleTimeoutDisplay() {
            return toText(this.sessionIdleTimeoutMillis);
        },
        sessionMaxTimeoutDisplay() {
            return toText(this.sessionMaxTimeoutMillis);
        },
        unlockedIdleTimeoutDisplay() {
            return toText(this.unlockedIdleTimeoutMillis);
        },
        unlockedMaxTimeoutDisplay() {
            return toText(this.unlockedMaxTimeoutMillis);
        },
        authenticatedIdleTimeoutDisplay() {
            return toText(this.authenticatedIdleTimeoutMillis);
        },
        authenticatedMaxTimeoutDisplay() {
            return toText(this.authenticatedMaxTimeoutMillis);
        },
        // name: {
        //     get() {
        //         if (this.user.name) {
        //             return this.user.name;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         this.$store.dispatch('editUser', { name: value });
        //     },
        // },
        // email: {
        //     get() {
        //         if (this.user.email) {
        //             return this.user.email;
        //         }
        //         return '';
        //     },
        //     set(value) {
        //         // TODO: need email verification first ... let server set it after user clicks link ... server should reject editUser { email } , it should make available a workflow for changing email that a client can initiate
        //         this.$store.dispatch('editUser', { email: value });
        //     },
        // },
        isEditUnlockedIdleTimeoutFormComplete() {
            return typeof this.unlockedIdleTimeoutMillis === 'number';
        },
        isEditUnlockedMaxTimeoutFormComplete() {
            return typeof this.unlockedMaxTimeoutMillis === 'number';
        },
        isEditAuthenticatedIdleTimeoutFormComplete() {
            return typeof this.authenticatedIdleTimeoutMillis === 'number';
        },
        isEditAuthenticatedMaxTimeoutFormComplete() {
            return typeof this.authenticatedMaxTimeoutMillis === 'number';
        },
        isEditSessionIdleTimeoutMillisFormComplete() {
            return typeof this.sessionIdleTimeoutMillis === 'number';
        },
        isEditSessionMaxTimeoutMillisFormComplete() {
            return typeof this.sessionMaxTimeoutMillis === 'number';
        },
        isDialogEditTimeoutFormComplete() {
            return typeof this.dialogEditTimeoutValue === 'number';
        },
        filteredUnlockedIdleTimeoutChoices() {
            // returns only the choices in unlockedTimeoutChoices that are LESS THAN OR EQUAL TO the current selction of unlockedMaxTimeout
            const maximum = this.unlockedMaxTimeoutMillis ?? this.defaultUnlockedMaxTimeoutMillis;
            // return this.unlockedIdleTimeoutChoices.filter((item) => item.value === 0 || item.value <= maximum);
            return this.unlockedIdleTimeoutChoices.map((item) => (item.value <= maximum ? item : { text: `${item.text} *`, value: item.value }));
        },
        filteredUnlockedMaxTimeoutChoices() {
            // returns only the choices in unlockedTimeoutChoices that are LESS THAN OR EQUAL TO the configured realm maximum
            // const maximum = toMillis({ years: 10 }); // this.unlockedIdleTimeoutMillis ?? this.defaultUnlockedIdleTimeoutMillis;  // TODO: read the realm configuration to set the maximum value (separate from the realm default)
            const minimum = this.unlockedIdleTimeoutMillis ?? this.defaultUnlockedIdleTimeoutMillis;
            const maximum = this.authenticatedIdleTimeoutMillis ?? this.defaultAuthenticatedIdleTimeoutMillis;
            // return this.unlockedMaxTimeoutChoices.filter((item) => item.value === 0 || item.value <= maximum);
            return this.unlockedMaxTimeoutChoices.map((item) => (item.value >= minimum && item.value <= maximum ? item : { text: `${item.text} *`, value: item.value }));
        },
        filteredAuthenticatedIdleTimeoutChoices() {
            // returns only the choices in authenticatedTimeoutChoices that are GREATER THAN OR EQUAL TO than the current selection of unlockedMaxTimeoutMillis *and* LESS THAN OR EQUAL TO the current selection of authenticatedMaxTimeout
            const minimum = this.unlockedMaxTimeoutMillis ?? this.defaultUnlockedMaxTimeoutMillis;
            const maximum = this.authenticatedMaxTimeoutMillis ?? this.defaultAuthenticatedMaxTimeoutMillis;
            // return this.authenticatedIdleTimeoutChoices.filter((item) => item.value === 0 || (item.value >= minimum && item.value <= maximum));
            return this.authenticatedIdleTimeoutChoices.map((item) => (item.value >= minimum && item.value <= maximum ? item : { text: `${item.text} *`, value: item.value }));
        },
        filteredAuthenticatedMaxTimeoutChoices() {
            // returns only the choices in authenticatedTimeoutChoices that are GREATER THAN OR EQUAL TO than the current selection of authenticatedIdleTimeoutMillis
            const minimum = this.authenticatedIdleTimeoutMillis ?? this.defaulAuthenticatedIdleTimeoutMillis;
            const maximum = this.sessionIdleTimeoutMillis ?? this.defaultSessionIdleTimeoutMillis;
            // return this.authenticatedMaxTimeoutChoices.filter((item) => item.value === 0 || item.value >= minimum);
            return this.authenticatedMaxTimeoutChoices.map((item) => (item.value >= minimum && item.value <= maximum ? item : { text: `${item.text} *`, value: item.value }));
        },
        filteredSessionIdleTimeoutChoices() {
            // returns only the choices in sessionTimeoutChoices that are GREATER THAN OR EQUAL TO the current selection of authenticatedMaxTimeoutMillis *and* LESS THAN OR EQUAL TO the current selection of sessionMaxTimeout
            const minimum = this.authenticatedMaxTimeoutMillis ?? this.defaultAuthenticatedMaxTimeoutMillis;
            const maximum = this.sessionMaxTimeoutMillis ?? this.defaultSessionMaxTimeoutMillis;
            // return this.sessionIdleTimeoutChoices.filter((item) => item.value === 0 || (item.value >= minimum && item.value <= maximum));
            return this.sessionIdleTimeoutChoices.map((item) => (item.value >= minimum && item.value <= maximum ? item : { text: `${item.text} *`, value: item.value }));
        },
        filteredSessionMaxTimeoutChoices() {
            // returns only the choices in sessionTimeoutChoices that are GREATER THAN OR EQUAL TO the current selection of sessionIdleTimeoutMillis
            const minimum = this.sessionIdleTimeoutMillis ?? this.defaultSessionIdleTimeoutMillis;
            // return this.sessionMaxTimeoutChoices.filter((item) => item.value === 0 || item.value >= minimum);
            return this.sessionMaxTimeoutChoices.map((item) => (item.value >= minimum ? item : { text: `${item.text} *`, value: item.value }));
        },
        isMechanismReadyPassword() {
            const passwordCreatedOn = this.authnMechanismInfo[AUTHN_PASSWORD]?.created_on;
            return Number.isInteger(passwordCreatedOn) && passwordCreatedOn > 0;
        },
        isMechanismReadyLoginShield() {
            const loginshieldCreatedOn = this.authnMechanismInfo[AUTHN_LOGINSHIELD]?.created_on;
            const loginshieldConfirmed = this.authnMechanismInfo[AUTHN_LOGINSHIELD]?.confirmed; // means user did first login with loginshield to associate their loginshield public key with this profile
            return Number.isInteger(loginshieldCreatedOn) && loginshieldCreatedOn > 0 && loginshieldConfirmed;
        },
        // isIdentityNameComplete() {
        //     return this.user
        //     && this.editablePassword
        //     && this.editablePassword !== this.user.name;
        // },
        // isIdentityEmailComplete() {
        //     return this.user
        //     && this.editableEmail
        //     && this.editableEmail !== this.user.email;
        // },
    },
    watch: {
        // dialogChangePassword(display) {
        //     if (display) {
        //         this.editablePassword = this.user.info.password;
        //     }
        // },
        email(val) {
            this.editableEmail = val;
        },
        authnMechanism() {
            this.updateMechanismChoices();
        },
        authnMechanismAvailableList() {
            this.updateMechanismChoices();
        },
    },
    methods: {
        async init() {
            console.log('PreferencesAuthn.vue: init');
            this.getPolicy();
            this.getUserSettings();
        },
        async getPolicy() {
            console.log('PreferencesAuthn.vue: getPolicy');
            try {
                this.$store.commit('loading', { getPolicy: true });
                // load realm settings from server
                const policy = await this.$client.realm(this.$route.params.realm).authn.getPolicy();
                // available authentication mechanisms
                this.authnMechanismAvailableList = policy.authnMechanismList ?? [];
                // timeouts
                this.defaultUnlockedIdleTimeoutMillis = policy.defaultUnlockedIdleTimeoutMillis ?? toMillis({ minutes: 15 });
                this.defaultUnlockedMaxTimeoutMillis = policy.defaultUnlockedMaxTimeoutMillis ?? toMillis({ hours: 1 });
                this.defaultAuthenticatedIdleTimeoutMillis = policy.defaultAuthenticatedIdleTimeoutMillis ?? toMillis({ hours: 4 });
                this.defaultAuthenticatedMaxTimeoutMillis = policy.defaultAuthenticatedMaxTimeoutMillis ?? toMillis({ days: 1 });
                this.defaultSessionIdleTimeoutMillis = policy.defaultSessionIdleTimeoutMillis ?? toMillis({ days: 90 });
                this.defaultSessionMaxTimeoutMillis = policy.defaultSessionMaxTimeoutMillis ?? toMillis({ years: 1 });
                // update the default choice of each list with actual value
                this.unlockedIdleTimeoutChoices = [...this.unlockedTimeoutChoices];
                this.unlockedIdleTimeoutChoices.unshift({ text: `Default (${toText(this.defaultUnlockedIdleTimeoutMillis)})`, value: 0 });
                this.unlockedMaxTimeoutChoices = [...this.unlockedTimeoutChoices];
                this.unlockedMaxTimeoutChoices.unshift({ text: `Default (${toText(this.defaultUnlockedMaxTimeoutMillis)})`, value: 0 });
                this.authenticatedIdleTimeoutChoices = [...this.authenticatedTimeoutChoices];
                this.authenticatedIdleTimeoutChoices.unshift({ text: `Default (${toText(this.defaultAuthenticatedIdleTimeoutMillis)})`, value: 0 });
                this.authenticatedMaxTimeoutChoices = [...this.authenticatedTimeoutChoices];
                this.authenticatedMaxTimeoutChoices.unshift({ text: `Default (${toText(this.defaultAuthenticatedMaxTimeoutMillis)})`, value: 0 });
                this.sessionIdleTimeoutChoices = [...this.sessionTimeoutChoices];
                this.sessionIdleTimeoutChoices.unshift({ text: `Default (${toText(this.defaultSessionIdleTimeoutMillis)})`, value: 0 });
                this.sessionMaxTimeoutChoices = [...this.sessionTimeoutChoices];
                this.sessionMaxTimeoutChoices.unshift({ text: `Default (${toText(this.defaultSessionMaxTimeoutMillis)})`, value: 0 });

                this.setUserTimeoutValues();
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load realm settings', message: 'Please contact customer support' });
            } finally {
                this.$store.commit('loading', { getPolicy: false });
            }
        },
        setUserTimeoutValues() {
            if (this.isAuthenticated && this.user && this.user.info) {
                console.log(`user: ${JSON.stringify(this.user)}`);
                this.unlockedIdleTimeoutMillis = this.user.info.unlockedIdleTimeoutMillis ?? this.defaultUnlockedIdleTimeoutMillis;
                this.unlockedMaxTimeoutMillis = this.user.info.unlockedMaxTimeoutMillis ?? this.defaultUnlockedMaxTimeoutMillis;
                this.authenticatedIdleTimeoutMillis = this.user.info.authenticatedIdleTimeoutMillis ?? this.defaultAuthenticatedIdleTimeoutMillis;
                this.authenticatedMaxTimeoutMillis = this.user.info.authenticatedMaxTimeoutMillis ?? this.defaultAuthenticatedMaxTimeoutMillis;
                this.sessionIdleTimeoutMillis = this.user.info.sessionIdleTimeoutMillis ?? this.defaultSessionIdleTimeoutMillis;
                this.sessionMaxTimeoutMillis = this.user.info.sessionMaxTimeoutMillis ?? this.defaultSessionMaxTimeoutMillis;
            }
        },
        async getUserSettings() {
            try {
                this.$store.commit('loading', { getUserSettings: true });
                const result = await this.$client.realm(this.$route.params.realm).authn.getUserSettings();
                if (result?.status) {
                    // changed from this.user?.info?.authn to result.status.primary with no default to indicate to user that they might need to select a primary mechanism
                    this.authnMechanism = result.status.primary; // ?? this.user?.info?.authn ?? AUTHN_PASSWORD; // TODO: instead of default to password, we should prompt the user to select an available mechanism... we should only set a default if the realm settings specify a default OR if there's only one mechanism available.
                    this.authnMechanismInfo = result.status.mechanism; // map of mechanism info => { object with details }
                }
            } catch (err) {
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load your authentication settings', message: 'Please contact customer support' });
            } finally {
                this.$store.commit('loading', { getUserSettings: false });
            }
        },
        updateMechanismChoices() {
            if (Array.isArray(this.authnMechanismAvailableList)) {
                // TODO: maybe instead of resetting the entire thing, create the list once when we load the policy, and in this update function just iterate to update the 'enabled' attribute?
                this.authnMechanismChoices = this.authnMechanismAvailableList.map((item) => ({
                    value: item,
                    text: this.authnMechanismDisplayName[item] ?? item,
                    tooltip: this.authnMechanismTooltip[item],
                    enabled: this.authnMechanism === item, // this.authnMechanismAvailableList.findIndex(item) > -1,
                }));
                this.authnMechanismSelected = this.authnMechanismChoices.findIndex((item) => item.value === this.authnMechanism);
            }
        },
        showDialogEditTimeout(key) {
            const info = {
                unlockedIdleTimeoutMillis: {
                    title: 'lock session due to inactivity',
                    inputLabel: 'Automatically lock session after...',
                    choices: 'filteredUnlockedIdleTimeoutChoices',
                },
                unlockedMaxTimeoutMillis: {
                    title: 'lock session a while after login',
                    inputLabel: 'Automatically lock session after...',
                    choices: 'filteredUnlockedMaxTimeoutChoices',
                },
                authenticatedIdleTimeoutMillis: {
                    title: 'auto-logout due to inactivity',
                    inputLabel: 'Automatically logout after...',
                    choices: 'filteredAuthenticatedIdleTimeoutChoices',
                },
                authenticatedMaxTimeoutMillis: {
                    title: 'auto-logout a while after login',
                    inputLabel: 'Automatically logout after...',
                    choices: 'filteredAuthenticatedMaxTimeoutChoices',
                },
                sessionIdleTimeoutMillis: {
                    title: 'expire session due to inactivity',
                    inputLabel: 'Automatically expire session after...',
                    choices: 'filteredSessionIdleTimeoutChoices',
                },
                sessionMaxTimeoutMillis: {
                    title: 'expire session a while after login',
                    inputLabel: 'Automatically expire session after...',
                    choices: 'filteredSessionMaxTimeoutChoices',
                },
            };
            this.dialogEditTimeoutTitle = info[key].title;
            this.dialogEditTimeoutKey = key;
            this.dialogEditTimeoutValue = this[key];
            this.dialogEditTimeoutChoices = this[info[key].choices];
            this.dialogEditTimeoutInputLabel = info[key].inputLabel;
            this.dialogEditTimeoutVisible = true;
        },
        /**
         * Label is what to show in the snackbars: Updated ${label} or Failed to update ${label}
         * KvMap is what to send to the server; could be one or more key-value pairs
         * When isCascade is false (deafult), we show snackbar on success and update user's timeout display.
         * When isCascade is true, we do not show a snackbar or update user's timeout display because another
         * call will be made later for the final update and it will have isCascade = false.
         */
        async editTimeout(label, kvMap = {} /* isCascade = false */) {
            console.log(`editTimeout label ${label}`);
            try {
                this.$store.commit('loading', { [label]: true });
                const isEdited = await this.$store.dispatch('editCurrentUser', { ...kvMap });
                if (isEdited /* && !isCascade */) {
                    this.$bus.$emit('snackbar', { type: 'success', message: `Updated ${label}` });
                    await this.$store.dispatch('loadUser');
                    this.setUserTimeoutValues();
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: `Failed to update ${label}` });
                }
            } catch (err) {
                console.error('editTimeout failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: `Failed to update ${label}` });
                this.setUserTimeoutValues();
                // TODO: if the error was because the session is locked (and must be unlocked to change security settings) we should show a dialog telling the user this is the issue and with a button for the user to unlock , which would redirect to login page with "next" route pointing back to this page so user can return to what they were doing.
            } finally {
                this.$store.commit('loading', { [label]: false });
            }
        },
        /**
         * key is the name of the setting in the 'order' array
         */
        async editTimeoutWithCascade(key) {
            const orderBase = [
                {
                    key: 'unlockedIdleTimeoutMillis', choices: this.unlockedTimeoutChoices, label: 'unlocked idle timeout', defaultKey: 'defaultUnlockedIdleTimeoutMillis',
                },
                {
                    key: 'unlockedMaxTimeoutMillis', choices: this.unlockedTimeoutChoices, label: 'unlocked max timeout', defaultKey: 'defaultUnlockedMaxTimeoutMillis',
                },
                {
                    key: 'authenticatedIdleTimeoutMillis', choices: this.authenticatedTimeoutChoices, label: 'authenticated idle timeout', defaultKey: 'defaultAuthenticatedIdleTimeoutMillis',
                },
                {
                    key: 'authenticatedMaxTimeoutMillis', choices: this.authenticatedTimeoutChoices, label: 'authenticated max timeout', defaultKey: 'defaultAuthenticatedMaxTimeoutMillis',
                },
                {
                    key: 'sessionIdleTimeoutMillis', choices: this.sessionTimeoutChoices, label: 'session idle timeout', defaultKey: 'defaultSessionIdleTimeoutMillis',
                },
                {
                    key: 'sessionMaxTimeoutMillis', choices: this.sessionTimeoutChoices, label: 'session max timeout', defaultKey: 'defaultSessionMaxTimeoutMillis',
                },
            ];
            // this[key] and edit[key] are currently using the same identifiers, so fill in the blanks
            const order = orderBase.map((item) => {
                const copy = item;
                copy.dataKey ??= copy.key;
                copy.editKey ??= copy.key;
                return copy;
            });

            const idx = order.findIndex((item) => item.key === key);
            if (idx < -1) {
                throw new Error(`invalid key: ${key}`);
            }
            console.log(`editTimeoutWithCascade key ${key} at index ${idx}`);
            // In the order above, [a,b] means we need to constrain the values such that
            // a <= b, so if user makes a > b, we update b so that b >= a again, and then
            // cascade that change up.
            // We do the same in the opposite direction: if user makes b < a, we update a
            // so that a <= b again, and then cascade that change down.
            // As we identify more settings that need to be adjusted higher or lower,
            // we add them to the map of settings to edit so that we can send them
            // all to the server at the same time, and we also add their labels to the
            // list so we can inform the user what was updated.
            const edit = {
                [order[idx].editKey]: this[order[idx].dataKey],
            };
            const labels = [
                order[idx].label,
            ];
            for (let i = idx; i < order.length - 1; i += 1) {
                console.log(`editTimeoutWithCascade up order[i] ${order[i].key} order[i+1] ${order[i + 1].key}`);
                const { dataKey, defaultKey } = order[i];
                const {
                    dataKey: nextDataKey, editKey: nextEditKey, choices, label: nextLabel, defaultKey: nextDefaultKey,
                } = order[i + 1];
                console.log(`editTimeoutWithCascade up: i ${i}, dataKey ${dataKey} nextDataKey ${nextDataKey}, this[dataKey] ${this[dataKey]} >? this[nextDataKey] ${this[nextDataKey]}`);
                const effectiveValue = this[dataKey] || this[defaultKey];
                const effectiveNextValue = this[nextDataKey] || this[nextDefaultKey];
                if (effectiveValue > effectiveNextValue) {
                    console.log(`editTimeoutWithCascade up looking for value >= ${effectiveValue} in choices: ${JSON.stringify(choices)}`);
                    const nextValue = choices.find((item) => item.value >= effectiveValue).value;
                    console.log(`editTimeoutWithCascade ${dataKey} > ${nextDataKey} so adjusting to ${nextValue}`);
                    this[nextDataKey] = nextValue;
                    edit[nextEditKey] = nextValue;
                    labels.push(nextLabel);
                }
            }
            for (let i = idx; i > 0; i -= 1) {
                console.log(`editTimeoutWithCascade down order[i] ${order[i].key} order[i-1] ${order[i - 1].key}`);
                const { dataKey, defaultKey } = order[i];
                const {
                    dataKey: prevDataKey, editKey: prevEditKey, choices, label: prevLabel, defaultKey: prevDefaultKey,
                } = order[i - 1];
                console.log(`editTimeoutWithCascade down: i ${i}, dataKey ${dataKey} prevDataKey ${prevDataKey}, this[dataKey] ${this[dataKey]} <? this[prevEditKey] ${this[prevEditKey]}`);
                const effectiveValue = this[dataKey] || this[defaultKey];
                const effectivePrevValue = this[prevDataKey] || this[prevDefaultKey];
                if (effectiveValue < effectivePrevValue) {
                    console.log(`editTimeoutWithCascade down looking for value <= ${effectiveValue} in choices: ${JSON.stringify(choices)}`);
                    const prevValue = [...choices].reverse().find((item) => item.value <= effectiveValue).value;
                    console.log(`editTimeoutWithCascade ${dataKey} < ${prevEditKey} so adjusting to ${prevValue}`);
                    this[prevDataKey] = prevValue;
                    edit[prevEditKey] = prevValue;
                    labels.unshift(prevLabel);
                }
            }
            const label = labels.join(', ');
            await this.editTimeout(label, edit);
        },
        async dialogEditTimeoutSubmit() {
            const key = this.dialogEditTimeoutKey;
            console.log(`dialogEditTimeoutSubmit key: ${key}`);
            this[key] = this.dialogEditTimeoutValue;
            await this.editTimeoutWithCascade(key);
            this.dialogEditTimeoutVisible = false;
        },
        dialogEditTimeoutCancel() {
            this.setUserTimeoutValues(); // reset to stored values and defaults
            this.dialogEditTimeoutVisible = false;
        },
        async editUnlockedIdleTimeoutMillis(/* isCascade = false */) {
            // if (this.unlockedIdleTimeoutMillis > this.unlockedMaxTimeoutMillis) {
            //     this.unlockedMaxTimeoutMillis = this.unlockedTimeoutChoices.find((item) => item.value >= this.unlockedIdleTimeoutMillis).value;
            //     await this.editUnlockedMaxTimeoutMillis(true);
            // }
            // await this.editTimeout('unlocked idle timeout', { unlockedIdleTimeoutMillis: this.unlockedIdleTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('unlockedIdleTimeoutMillis');
            this.dialogEditUnlockedIdleTimeoutMillis = false;
        },
        async editUnlockedMaxTimeoutMillis(/* isCascade = false */) {
            // if (this.unlockedMaxTimeoutMillis > this.authenticatedIdleTimeoutMillis) {
            //     this.authenticatedIdleTimeoutMillis = this.authenticatedTimeoutChoices.find((item) => item.value >= this.unlockedMaxTimeoutMillis).value;
            //     await this.editAuthenticatedIdleTimeoutMillis(true);
            // }
            // await this.editTimeout('unlocked max timeout', { unlockedMaxTimeoutMillis: this.unlockedMaxTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('unlockedMaxTimeoutMillis');
            this.dialogEditUnlockedMaxTimeoutMillis = false;
        },
        async editAuthenticatedIdleTimeoutMillis(/* isCascade = false */) {
            // if (this.authenticatedIdleTimeoutMillis > this.authenticatedMaxTimeoutMillis) {
            //     this.authenticatedMaxTimeoutMillis = this.authenticatedTimeoutChoices.find((item) => item.value >= this.authenticatedIdleTimeoutMillis).value;
            //     await this.editAuthenticatedMaxTimeoutMillis(true);
            // }
            // await this.editTimeout('authenticated idle timeout', { authenticatedIdleTimeoutMillis: this.authenticatedIdleTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('authenticatedIdleTimeoutMillis');
            this.dialogEditAuthenticatedIdleTimeoutMillis = false;
        },
        async editAuthenticatedMaxTimeoutMillis(/* isCascade = false */) {
            // if (this.authenticatedMaxTimeoutMillis > this.sessionIdleTimeoutMillis) {
            //     this.sessionIdleTimeoutMillis = this.sessionTimeoutChoices.find((item) => item.value >= this.authenticatedMaxTimeoutMillis).value;
            //     await this.editSessionIdleTimeoutMillis(true);
            // }
            // if (this.authenticatedMaxTimeoutMillis < this.authenticatedIdleTimeoutMillis) {
            //     this.authenticatedIdleTimeoutMillis = this.authenticatedTimeoutChoices.find((item) => item.value <= this.authenticatedMaxTimeoutMillis).value;
            //     await this.editAuthenticatedIdleTimeoutMillis(true);
            // }
            // await this.editTimeout('authenticated max timeout', { authenticatedMaxTimeoutMillis: this.authenticatedMaxTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('authenticatedMaxTimeoutMillis');
            this.dialogEditAuthenticatedMaxTimeoutMillis = false;
        },
        async editSessionIdleTimeoutMillis(/* isCascade = false */) {
            // if (this.sessionIdleTimeoutMillis > this.sessionMaxTimeoutMillis) {
            //     this.sessionMaxTimeoutMillis = this.sessionTimeoutChoices.find((item) => item.value >= this.sessionIdleTimeoutMillis).value;
            //     await this.editSessionMaxTimeoutMillis(true);
            // }
            // if (this.sessionIdleTimeoutMillis < this.authenticatedMaxTimeoutMillis) {
            //     this.authenticatedMaxTimeoutMillis = this.authenticatedTimeoutChoices.find((item) => item.value <= this.sessionIdleTimeoutMillis).value;
            //     await this.editAuthenticatedMaxTimeoutMillis(true);
            // }
            // await this.editTimeout('session idle timeout', { sessionIdleTimeoutMillis: this.sessionIdleTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('sessionIdleTimeoutMillis');
            this.dialogEditSessionIdleTimeoutMillis = false;
        },
        async editSessionMaxTimeoutMillis(/* isCascade = false */) {
            // if (this.sessionMaxTimeoutMillis < this.sessionIdleTimeoutMillis) {
            //     this.sessionIdleTimeoutMillis = this.sessionTimeoutChoices.find((item) => item.value <= this.sessionMaxTimeoutMillis).value;
            //     await this.editSessionIdleTimeoutMillis(true);
            // }
            // await this.editTimeout('session max timeout', { sessionMaxTimeoutMillis: this.sessionMaxTimeoutMillis }, isCascade);
            await this.editTimeoutWithCascade('sessionMaxTimeoutMillis');
            this.dialogEditSessionMaxTimeoutMillis = false;
        },
        async restoreDefaultTimeouts() {
            await this.editTimeout('all timeouts', {
                unlockedIdleTimeoutMillis: 0, // this.unlockedIdleTimeoutMillis
                unlockedMaxTimeoutMillis: 0, // this.unlockedMaxTimeoutMillis,
                authenticatedIdleTimeoutMillis: 0, // this.authenticatedIdleTimeoutMillis,
                authenticatedMaxTimeoutMillis: 0, // this.authenticatedMaxTimeoutMillis,
                sessionIdleTimeoutMillis: 0, // this.sessionIdleTimeoutMillis,
                sessionMaxTimeoutMillis: 0, // this.sessionMaxTimeoutMillis,
            });
        },
        async loadUserLoginShieldStatus() {
            try {
                this.$store.commit('loading', { loadUserLoginShieldStatus: true });
                this.loginshield = await this.$client.realm(this.$route.params.realm).authn.getLoginShieldSettings();
            } catch (err) {
                this.loginshield = null;
                console.error('failed to load user loginshield status', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to load some information', message: 'The settings shown may not be accurate' });
            } finally {
                this.$store.commit('loading', { loadUserLoginShieldStatus: false });
            }
        },
        async toggleLoginShieldActivation(value) {
            this.$bus.$emit('snackbar', { type: 'info', message: `switch is now ${value}` });
            if (value) {
                this.activateLoginShield();
            } else {
                this.deactivateLoginShield();
            }
        },
        /**
         * This is used for both first-time activation and also when user
         * re-activates LoginShield; we send the request to the server, and
         * the server will check the user's LoginShield settings and decide
         * what needs to be done next.
         */
        async activateLoginShield() {
            try {
                this.$store.commit('loading', { activateLoginShield: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.edit({ authn: 'loginshield' });
                console.log(`activateLoginShield response ${JSON.stringify(response)}`);
                // this.$bus.$emit('snackbar', { type: 'info', headline: 'ok...' });
                const { isEdited, nextRoute, redirect } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'success', headline: 'Activated LoginShield' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to activate LoginShield' });
                }
                if (nextRoute) {
                    this.$router.push(nextRoute);
                    return;
                }
                if (redirect) {
                    window.location.href = redirect;
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to activate loginshield', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to activate LoginShield', message: 'Please contact customer support for assistance' });
            } finally {
                this.$store.commit('loading', { activateLoginShield: false });
            }
        },
        async deactivateLoginShield() {
            try {
                this.$store.commit('loading', { deactivateLoginShield: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.edit({ authn: 'password' });
                console.log(`deactivateLoginShield response ${JSON.stringify(response)}`);
                // this.$bus.$emit('snackbar', { type: 'info', headline: 'ok...' });
                const { isEdited, nextRoute, redirect } = response;
                if (isEdited) {
                    this.$bus.$emit('snackbar', { type: 'warn', headline: 'Deactivated LoginShield' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deactivate LoginShield' });
                }
                if (nextRoute) {
                    this.$router.push(nextRoute);
                    return;
                }
                if (redirect) {
                    window.location.href = redirect;
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to deactivate loginshield', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to deactivate LoginShield', message: 'Please contact customer support for assistance' });
            } finally {
                this.$store.commit('loading', { deactivateLoginShield: false });
            }
        },
        async startSetupActivity() {
            try {
                this.$store.commit('loading', { startSetupActivity: true });
                const response = await this.$client.realm(this.$route.params.realm).authn.setup();
                console.log(`startSetupActivity response ${JSON.stringify(response)}`);
                const { type } = response;
                if (type === RESPONSE_TYPE_INTENT) {
                    const { intent, intent_params: intentParams = {} } = response;
                    switch (intent) {
                    case INTENT_SETUP: {
                        const { setup_request_id: setupRequestId } = intentParams;
                        this.$router.push({ name: 'realm-profile-setup', params: { realm: this.$route.params.realm }, query: { i: setupRequestId } });
                        return;
                    }
                    default:
                        console.error(`unsupported intent: ${intent}`);
                    }
                }
            } catch (err) {
                this.loginshield = null;
                console.error('failed to start setup', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to start setup', message: 'Please contact customer support for assistance' });
            } finally {
                this.$store.commit('loading', { startSetupActivity: false });
            }
        },
    },
    mounted() {
        this.init();
        this.loadUserLoginShieldStatus();
    },
};
</script>
