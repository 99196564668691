<template>
    <v-dialog :value="value" @input="$emit('input', $event.target ? $event.target.value : false)" max-width="600">
        <v-card elevation="4" class="pa-5">
            <v-card-text class="text-h6 pa-0 pb-3">Change password</v-card-text>
            <!-- <v-row justify="center" class="pt-3">
                <v-col cols="12">
                    <div class="font-weight-light text-body-1">
                        <p>Enter your old password.</p>
                        <p>Enter your new password.</p>
                        <p>Enter your new password again.</p>
                    </div>
                </v-col>
            </v-row> -->
            <ChangePasswordForm :token="token" @changed="$emit('input', false)" @cancelled="$emit('input', false)"/>
        </v-card>
    </v-dialog>
</template>

<script>
import ChangePasswordForm from '@/components/ChangePasswordForm.vue';

export default {
    components: {
        ChangePasswordForm,
    },
    props: {
        value: {
            type: Boolean,
            default: false,
            required: true,
        },
        token: {
            type: String,
            default: null,
            required: false,
        },
    },
};
</script>
