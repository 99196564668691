/*
Copyright (C) 2021 Jonathan Buhacoff. All rights reserved.
*/
/* eslint-disable no-console */

const SECOND = 1000; // milliseconds
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const YEAR = DAY * 365;

// returns the interval in milliseconds
function toMillis({
    years = 0,
    days = 0,
    hours = 0,
    minutes = 0,
    seconds = 0,
    milliseconds = 0,
}) {
    return (years * YEAR) + (days * DAY) + (hours * HOUR) + (minutes * MINUTE) + (seconds * SECOND) + milliseconds;
}

// returns the interval in seconds
// if the interval includes non-zero milliseconds, answer will be rounded down to make an integer seconds
function toSeconds(interval) {
    return Math.floor(toMillis(interval) / 1000);
}

function toText(millis) {
    const display = [];
    let remainder = millis;
    if (remainder >= YEAR) {
        const years = remainder / YEAR;
        remainder %= YEAR;
        const text = years > 1 ? 'years' : 'year';
        display.push(`${years} ${text}`);
    }
    if (remainder >= DAY) {
        const days = remainder / DAY;
        remainder %= DAY;
        const text = days > 1 ? 'days' : 'day';
        display.push(`${days} ${text}`);
    }
    if (remainder >= HOUR) {
        const hours = remainder / HOUR;
        remainder %= HOUR;
        const text = hours > 1 ? 'hours' : 'hour';
        display.push(`${hours} ${text}`);
    }
    if (remainder >= MINUTE) {
        const minutes = remainder / MINUTE;
        remainder %= MINUTE;
        const text = minutes > 1 ? 'minutes' : 'minute';
        display.push(`${minutes} ${text}`);
    }
    if (remainder >= SECOND) {
        const seconds = remainder / SECOND;
        remainder %= SECOND;
        const text = seconds > 1 ? 'seconds' : 'second';
        display.push(`${seconds} ${text}`);
    }
    if (remainder >= 1) {
        const text = 'ms';
        display.push(`${remainder} ${text}`);
    }
    return display.join(' ');
}

module.exports = { toMillis, toSeconds, toText };
