<template>
    <v-form @submit="changePassword" onSubmit="return false;" @keyup.enter.native="changePassword">
        <slot name="pretext"></slot>
        <v-text-field
            v-model="currentPassword"
            label="Current password"
            ref="currentPasswordInput"
            outlined
            dense
            @keydown="checkForCapsLock"
            :type="currentPasswordInputType"
            v-if="isCurrentPasswordRequired"
        >
            <template #prepend-inner>
                <font-awesome-icon :icon="['fas', 'lock']" fixed-width class="mt-1"></font-awesome-icon>
            </template>
            <template #append-outer>
                <v-btn icon @click="currentPasswordVisible = !currentPasswordVisible" :color="currentPasswordVisible ? 'blue darken-1' : 'grey darken-1'" class="ma-0 pa-0">
                    <font-awesome-icon :icon="['fas', 'eye']"/>
                </v-btn>
            </template>
        </v-text-field>
        <v-text-field
            v-model="newPassword"
            label="New password"
            ref="newPasswordInput"
            outlined
            @keydown="checkForCapsLock"
            :type="newPasswordInputType"
            :loading="newPasswordScoreVisible"
        >
            <template #prepend-inner>
                <font-awesome-icon :icon="['fas', 'unlock']" fixed-width class="mt-1"></font-awesome-icon>
            </template>
            <template #append>
                <v-btn text color="indigo" @click="generateNewPassword">Generate</v-btn>
                <v-btn icon @click="newPasswordVisible = !newPasswordVisible" :color="newPasswordVisible ? 'blue darken-1' : 'grey darken-1'" class="ma-0 pa-0">
                    <font-awesome-icon :icon="['fas', 'eye']"/>
                </v-btn>
            </template>
            <template #progress>
                <v-progress-linear v-model="newPasswordScorePercent" :color="newPasswordScoreColor" absolute rounded class="mt-1" height="12">
                    <span style="font-size: 0.7em" :style="{ color: newPasswordScore === 4 ? 'white' : 'black' }">{{ newPasswordScoreText }}</span>
                </v-progress-linear>
            </template>
        </v-text-field>

        <p class="text-caption">
            &nbsp; <!-- reserve the vertical space for this warning -->
            <span v-show="capsLockWarning">
            <font-awesome-icon :icon="['fas', 'exclamation-triangle']" size="1x" class="amber--text text--darken-4"></font-awesome-icon>
            Caps Lock is ON
            </span>
        </p>

        <v-row justify="center" no-gutters>
            <v-btn elevation="4" :style="primaryButtonStyle" @click="changePassword" :disabled="!isEditPasswordFormComplete">
                Save
            </v-btn>
            <v-btn text class="grey--text" @click="$emit('cancelled')">Cancel</v-btn>
        </v-row>
    </v-form>
</template>

<style lang="css">
.v-input__control .v-input__append-inner {
    margin-top: 10px; /* (56 input height - 36 button height) / 2 */
    margin-bottom: 10px;
    padding: 0px;
}
</style>

<script>
import { mapState, mapGetters } from 'vuex';
import { randomText } from '@/sdk/random';

export default {
    props: {
        token: {
            type: String,
            default: null,
            required: false,
        },
        display: {
            type: Boolean,
            default: true,
            required: false,
        },
    },
    data: () => ({
        currentPassword: null,
        currentPasswordVisible: false,
        newPassword: null,
        newPasswordVisible: false,
        newPasswordScore: null,
        capsLockWarning: false,
    }),
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
            account: (state) => state.account,
            realmInfo: (state) => state.realmInfo,
            palette: (state) => state.palette,
            focus: (state) => state.focus,
        }),
        ...mapGetters({
            // isLoading: 'isLoading',
            // brandName: 'brandName',
            // primaryColor: 'primaryColor',
            // primaryTextColor: 'primaryTextColor',
            // accentColor: 'accentColor',
            // cardTitleBarTextStyle: 'cardTitleBarTextStyle',
            // cardTitleBarStyle: 'cardTitleBarStyle',
            primaryButtonStyle: 'primaryButtonStyle',
            // primaryIconStyle: 'primaryIconStyle',
        }),
        isCurrentPasswordRequired() {
            return !this.token; // if a password reset token was provided, then current password is not required
        },
        currentPasswordInputType() {
            return this.currentPasswordVisible ? 'text' : 'password';
        },
        newPasswordInputType() {
            return this.newPasswordVisible ? 'text' : 'password';
        },
        newPasswordScoreVisible() {
            return typeof this.newPassword === 'string' && this.newPassword.length > 0 && typeof this.newPasswordScore === 'number';
        },
        newPasswordScorePercent() {
            if (typeof this.newPasswordScore === 'number') {
                // translate the 0..4 score to 20%, 40%, 60%, 80%, 100%
                return ((this.newPasswordScore + 1) * 100) / 5;
            }
            return 0;
        },
        newPasswordScoreColor() {
            switch (this.newPasswordScore) {
            case 0:
                return 'red darken-4';
            case 1:
                return 'red';
            case 2:
                return 'amber';
            case 3:
                return 'yellow';
            case 4:
                return 'green';
            default:
                return 'black';
            }
        },
        newPasswordScoreText() {
            switch (this.newPasswordScore) {
            case 0:
                return 'extremely weak';
            case 1:
                return 'weak';
            case 2:
                return 'weak';
            case 3:
                return 'moderate';
            case 4:
                return 'strong';
            default:
                return '';
            }
        },
        isEditPasswordFormComplete() {
            const current = !this.isCurrentPasswordRequired || (this.isCurrentPasswordRequired && this.currentPassword);
            return current && this.newPassword && typeof this.newPasswordScore === 'number' && this.newPasswordScore >= 3;
        },
    },
    watch: {
        newPassword(value) {
            this.checkNewPasswordQuality(value);
        },
        focus() {
            // TODO: check if we are visible first
            if (this.isCurrentPasswordRequired) {
                this.$activateInput('currentPasswordInput');
            } else {
                this.$activateInput('newPasswordInput');
            }
        },
        display(newValue) {
            console.log(`ChangePasswordForm display changed: ${newValue}`);
            if (newValue) {
                if (this.isCurrentPasswordRequired) {
                    this.$activateInput('currentPasswordInput');
                } else {
                    this.$activateInput('newPasswordInput');
                }
            }
        },
    },
    methods: {
        generateNewPassword() {
            this.newPassword = randomText(24);
            this.newPasswordVisible = true;
        },
        async checkNewPasswordQuality(value) {
            try {
                const token = this.token ? { token: this.token } : {};
                const response = await this.$client.realm(this.$route.params.realm).authn.checkPasswordQuality({ password: value, ...token });
                console.log(`response: ${JSON.stringify(response)}`);
                this.newPasswordScore = response.score;
            } catch (err) {
                console.log('checkPassword failed', err);
                this.newPasswordScore = null;
            }
        },
        async changePassword() {
            try {
                this.$store.commit('loading', { changePassword: true });
                const token = this.token ? { token: this.token } : {};
                const response = await this.$client.realm(this.$route.params.realm).authn.changePassword({ password: this.currentPassword, newPassword: this.newPassword, ...token });
                // this.name = this.editablePassword;
                // this.snackbarIdentityNameUpdated = true;
                if (response.isEdited) {
                    this.$emit('changed', true);
                    this.$bus.$emit('snackbar', { type: 'success', message: 'Changed password' });
                } else {
                    this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change password' });
                }
            } catch (err) {
                console.error('changePassword failed', err);
                this.$bus.$emit('snackbar', { type: 'error', message: 'Failed to change password' });
            } finally {
                this.$store.commit('loading', { changePassword: false });
            }
        },
        checkForCapsLock(event) {
            if (typeof event.getModifierState === 'function') {
                const isCapsLockOn = event.getModifierState('CapsLock');
                if (isCapsLockOn) {
                    this.capsLockWarning = true;
                } else {
                    this.capsLockWarning = false;
                }
            }
        },
    },
    mounted() {
        if (this.isCurrentPasswordRequired) {
            this.$activateInput('currentPasswordInput');
        } else {
            this.$activateInput('newPasswordInput');
        }
    },
};
</script>
